<template>
	<!-- 禁止页面横向滚动 -->
	<div class="content" style="overflow-x: hidden;">
		<goTop></goTop>
		<div class="banner-wrap">
			<el-image class="banner" fit="fill" :src="require('@/assets/img/banner_companyProduct.webp')" />
			<div class="banner-text-wrap">
				<beatText />
			</div>
		</div>

		<div class="list-wrap">
			<div class="list-item" v-for="(item, index) in list" :key="index">
				<el-image class="item-image" fit="fill" :src="item.imagePath" :style="{
            transform: index % 2 != 0 ? 'translateX(108%)' : 'translateX(0%)',
          }" />
				<div class="item-info" :style="{
            transform: index % 2 != 0 ? 'translateX(-108%)' : 'translateX(0%)',
          }">
					<div style="
              width: 120px;
              height: 14px;
              background: linear-gradient(90deg, #194fa8, #ffffff);
            " />
					<div class="item-title">
						{{ item.title }}
					</div>
					<div class="item-text">
						{{ item.text }}
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
	// banner文字
	import beatText from "@/components/beat-text2";
	// 回到顶部
	import goTop from '@/components/goTop.vue'
	export default {
		components: {
			beatText,
			goTop
		},
		data() {
			return {
				list: [{
						title: "大数据治理平台",
						text: "以城市基本信息流为基础，运用物联网基础设施、云计算基础设施、地理空间基础设施等新一代信息技术以及终端等工具和方法的应用，实现以用户创新、开放创新、大众创新、协同创新为特征的知识社会环境下的可持续创新，强调通过价值创造，以人为本实现经济、 社会、环境的全面可持续发展。",
						imagePath: require("@/assets/img/image_dsjzlpt.webp"),
					},
					{
						title: "大数据研判分析平台",
						text: "针对浙江边检业务框架和数据研判工作形势，深入定制开发面向全省数据研判民警的数据研判平台。平台主要完成数据 查询、数据分析、数据调用分发和日常管理4个子系统共10余个功能模块的建设。主要包括：数据任务模块、数据查询 模块、数据大屏模块、超级档案模块、统计分析模块、研判模型模块、数据挖掘模块、专案研判模块、数据调用模块、接口管理模块、指令服务模块、档案管理模块、消息管理模块、系统管理模块等。",
						imagePath: require("@/assets/img/image_dsjypfxpt.webp"),
					},
					{
						title: "大数据可视化平台",
						text: "通过三维表现技术来表示复杂的信息，实现海量数据的立体呈现。可视化技术借助人脑的视觉思维能力，通过挖掘数据之间重要的关联关系将若干关联性的可视化数据进行汇总处理，揭示数据中隐含的规律和发展趋势，用更直观的方式呈现和推导数据间的逻辑关系，从而提高数据的使用效率。",
						imagePath: require("@/assets/img/image_dsjkshpt.webp"),
					},
					{
						title: "大数据研判协作平台",
						text: "采用云计算架构，构建大数据平台。通过云计算、分布式存储、分布式计算、内存计算等技术手段提高数据处理能力，提高对非结构化数据，大容量数据的存储及处理能力，满足各类应用数据处理要求。",
						imagePath: require("@/assets/img/image_dsjypxzpt.webp"),
					},
					{
						title: "大数据共享开放平台",
						text: "平台基于“以用户需求为导向”的建设思路打造了统一数据开放服务能力，建立起政府与数据需求者之间的桥梁，从提升数据开放的数量、质量、类型和价值的角度，为开发者提供基于接口能力开放、服务开放、权限开放等数据开放模型，可让更多开发者能够基于平台进行针对数据开放设计的应用集成和分发，帮助数据管理与运营者有效整合和体系化管理数据开放的各项应用。",
						imagePath: require("@/assets/img/dsjgxkfpt.png"),
					},
				],
			};
		},
		// vue的两个生命钩子，这里不多解释。
		// window对象，所有浏览器都支持window对象。它表示浏览器窗口，监听滚动事件
		mounted () {
		},
		destroyed () {
		},
		methods: {
			
		},
	};
</script>
<style lang="scss" scoped>
	.content {
		width: 100%;
		display: flex;
		flex-flow: column;
		align-items: center;

		.banner-wrap {
			position: relative;

			.banner {
				width: 100%;
				height: 500px;
			}

			.banner-text-wrap {
				position: absolute;
				top: 50%;
				left: 50%;
				text-align: center;
				transform: translate(-50%, -50%);

				.banner-title {
					font-size: 54px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #ffffff;
				}

				.banner-text {
					font-size: 30px;
					font-family: Source Han Sans CN;
					font-weight: 300;
					color: #ffffff;
				}
			}
		}

		.list-wrap {
			width: 80%;
			height: fit-content;
			padding-bottom: 150px;

			.list-item {
				width: 100%;
				height: fit-content;
				margin-top: 150px;
				display: flex;
				justify-content: space-between;

				.item-image {
					width: 48%;
				}

				.item-info {
					width: 48%;
					height: 100%;
					display: flex;
					flex-flow: column;
					justify-content: center;
					height: initial;

					.item-title {
						font-size: 30px;
						font-family: Source Han Sans CN;
						font-weight: bold;
						margin-top: 24px;
						color: #2d2d2d;
					}

					.item-text {
						font-size: 20px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #666666;
						margin-top: 44px;
						line-height: 36px;
					}
				}
			}
		}
	}
</style>
